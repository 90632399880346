import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    item: {
      answerType: '',
      comments: '',
      contents: '',
      fileLoc: '',
      fileNm: '',
      noticeId: '',
      questionType: '',
      regDt: '',
      title: ''
    }
  })
  const fnDetail = async () => {
    const res = await proxy.$CustomerSvc.postMyQuestionDetail(route.query)
    if (res.resultCode === '0000') {
      proxy.$_.merge(state.item, res)
    }
  }
  const fnEdit = async item => {
    if (!proxy.$Util.isEmpty(item.comments)) return
    await router.replace({
      path: '/cs/my-question/write',
      query: { noticeId: item.noticeId }
    })
  }
  const fnDelete = async item => {
    if (!confirm('삭제하시겠습니까?')) return
    const params = {}
    params.noticeId = item.noticeId
    const res = await proxy.$CustomerSvc.postMyQuestionDelete(params)
    if (res.resultCode === '0000') {
      alert(res.resultMsg)
      await router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const init = async () => {
    await fnDetail()
  }
  /** init **/
  init()
  return { ...toRefs(state), fnDetail, fnEdit, fnDelete }
}
