<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="lump">
            <div class="notice_list_li">
              <div class="notice_list_ttl">
                <div class="left">
                  <span class="cate sec_color">{{ item.questionType }}</span>
                  <span>{{ item.title }}</span>
                </div>
                <div class="right"></div>
              </div>
              <div class="notice_list_con">
                <div class="left">
                  <div class="date">{{ item.regDt }}</div>
                </div>
                <div class="right"></div>
              </div>
            </div>
          </div>
          <div class="lump">
            <div class="notice_detail_con">
              <div class="editor">
                <div v-html="item.contents"></div>
              </div>
              <div class="upload_file">
                <span>첨부파일</span>
                <a
                  class="upload_file_name"
                  :href="item.fileLoc"
                  target="_blank"
                  >{{ item.fileNm }}</a
                >
              </div>
            </div>
          </div>
          <div class="lump" v-if="!$Util.isEmpty(item.comments)">
            <div class="notice_list_li">
              <div class="notice_list_ttl">
                <div class="left">
                  <span>회원님의 문의사항에 대한 답변드립니다.</span>
                </div>
                <div class="right"></div>
              </div>
              <div class="notice_list_con">
                <div class="left">
                  <div class="date">{{ item.regDt }}</div>
                  <div class="answer">
                    <div class="editor">
                      <div v-html="item.comments"></div>
                    </div>
                  </div>
                </div>
                <div class="right"></div>
              </div>
            </div>
          </div>
          <div class="empty_wrap" v-else>
            <div class="empty">
              <div>
                아직 등록된 답변내용이 없습니다.
              </div>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnDelete(item)">삭제</button>
              <button
                class="btn"
                v-if="$Util.isEmpty(item.comments)"
                @click="fnEdit(item)"
              >
                편집
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'cs-my-question-view-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
